<template>
  <DgLayoutPortalForgotPassword
    :logo="logo"
    :portalColor="portalSettings.portalColor"
    :style="style"
  >
    <router-view />
  </DgLayoutPortalForgotPassword>
</template>
<script>
import { mapState } from "vuex";
import { DgLayoutPortalForgotPassword } from "@engenharia/dashgoo-ui";

export default {
  components: {
    DgLayoutPortalForgotPassword,
  },
  computed: {
    ...mapState({
      portalSettings: (state) => state.portal?.settings,
    }),
    logo() {
      return this.portalSettings.image || null;
    },
    style() {
      const color = this.portalSettings.portalColor;
      return {
        background: `linear-gradient(
          to right,
          ${color} 0%,
          ${color} 50%,
          white 50%,
          white 100%
        )`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "100vh",
      };
    },
  },
  async mounted() {
    document.title = this.$t("FORGOT_PASSWORD");
  },
};
</script>
